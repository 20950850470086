'use strict';

import App from 'App';
import User from 'User';
import Server from 'server/Server';

import { TaskView, TaskPresentation, TaskWindowType} from 'views/tasks/Task';
import NavigatorView from 'views/tasks/navigators/simple/Navigator';
import DeviceUtils from 'utils/DeviceUtils';
import TabBarView from 'views/tasks/TabBar';
import TemplatesListPopupView from 'views/album/documents/TemplatesListPopup';
//import AlbumView from 'views/album/photos/Album';
import NotificationManager from 'utils/notification/NotificationManager';

import HeaderListItem from 'views/listing/renderer/header/HeaderListItem';
import Context from 'models/context/Context';

import GetRecordRequest from 'server/protocol/request/task/GetRecord';
import PrepareNewEntryRequest from 'server/protocol/request/task/PrepareNewEntry';
import GridActionRequest from 'server/protocol/request/task/GridAction';

import I18NUtils from 'utils/i18n/I18NUtils';
import StringUtils from 'utils/StringUtils';
import TemplateManager from 'utils/TemplateManager';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import LoadingMask from 'views/loading/LoadingMask';
import MenuItemView from './MenuItem';

import _ from 'lodash';
import PopupMenuNavigator from 'views/tasks/navigators/simple/PopupMenuNavigator';
import { PopupMenu } from '../../parametrage/structures/PopupMenu';

class InputTaskView extends TaskView {

    initInnerTaskProperties(){
        this.taskType = 'Invalid';
        this.className = 'inputTask';
    }

    initialize(options) {
        super.initialize(options);
        this.itemActionBuffer = {};
        this.navigatorType = 'grid';
        this.isFullScreenRecord = false;
        //This allows for exemple to open the album / documents at first
        //instead of the record
        this.defaultRecordPresentation = options.defaultRecordPresentation;

        CClientConfiguration.getGridView(options.taskConfig.getNavigatorGridView(), this.domainContext)
            .then((gridConfig) => {
                //Input tasks always have some navigator
                this.navigator = new NavigatorView({
                    //template: this.taskConfig.get('templates'),
                    task: this,
                    taskId: options.taskConfig.getId(),
                    title: options.taskConfig.getLabel(),
                    gridId: options.taskConfig.getNavigatorGridView(),
                    formatterId: options.taskConfig.getNavigatorFormater(),
                    filterId: options.taskConfig.getNavigatorFilter(),
                    //filters: this.taskModel.get('filter').elements,
                    //includedGraphs: _.filter(this.taskModel.get('graphs'), function(item){ return item.scope === 'Navigator' || item.scope === 'All'; }),
                    autoSelectIfSingle: true,
                    presentation: this.getDefaultNavigatorPresentation(),
                    lineExpanse: true,
                    domainContext: this.domainContext,
                    iconConfig: options.taskConfig.getIconConfig(),
                    headerId: options.taskConfig.getHeaderID(),
                    gridConfig: gridConfig
                });

                this.inputTaskActionMenu = undefined;
                this.handleOverrideProperties();
            })
            .catch((error) => {
                App.displayErrorMessage(error);
            });
    }

    getIconConfig() {
        let me = this;
        let navGridView = this.options.taskConfig.getNavigatorGridView();
        let navTableId = this.options.taskConfig.getNavigatorTableID();
        return new Promise((accept, reject) => {
            let allPromise = [];
            allPromise.push(CClientConfiguration.getGridIconConfig(navGridView,me.options.taskConfig.getId(), me.domainContext));
            allPromise.push(CClientConfiguration.getTableIconConfig(navTableId,me.options.taskConfig.getId(), me.domainContext));
            Promise.all(allPromise)
                .then((results) => {
                    let iconConfig = Object.assign({}, results[0], results[1]);
                    accept(iconConfig);
                })
                .catch(reject);
        });
    }

    getDefaultNavigatorPresentation(){
        return 'grid';
    }

    onDOMUpdated(source) {
        super.onDOMUpdated();

        //We notify the navigator that it's ready. This will allow the nav to load records
        if (this.navigator.onDOMUpdated && (!this.taskConfig.canNavigatorSkipFirstLoad() || DeviceUtils.isMobile())) {
            this.navigator.onDOMUpdated();
        }
    }

    render() {
        super.render();

        //Rendering it on the screen
        this.getMainNavigatorElement().hide();
        this.navigator.setElement(this.getMainNavigatorElement());
        this.navigator.render({
            displayTitle: false,
            dataContainerType: this.navigatorType
        });
        if(this.presentation & TaskPresentation.ShowNavigator === TaskPresentation.ShowNavigator) {
            this.getMainNavigatorElement().show();
        }
        //Listening to the selection on the navigator
        this.listenTo(this.navigator, 'itemSelected', this.onNavigatorItemSelected);
        this.listenTo(this.navigator, 'itemActionSelected', this.onNavigatorItemActionSelected);
        if (this.canCreate) {
            this.listenTo(this.navigator, 'newEntry', this.onNavigatorNewEntry);
        }

        //Renders the tab bar associated with the navigator
        this.renderNavigatorTabBar();
    }

    handleOverrideProperties() {
        let overrideProps = this.taskConfig.getInitialPropsOverride();
        for(let prop in overrideProps) {
            if(prop === 'filter') {
                this.navigator.setFilters(overrideProps.filter);
            }
        }
    }

    /**
     * Handles user click on navigator item action
     * @param {*} id The id of the action
     * @param {*} row The current row on which the user clicked
     * @param {*} item The itemId of the clicked action
     */
    onNavigatorItemActionSelected(id, row, item) {
        let me = this;
        let callTime = Date.now();
        let sourceId = row.cid;
        let lastCall = this.itemActionBuffer[sourceId];
        if(lastCall !== undefined) { return; }
        this.itemActionBuffer[sourceId] = callTime;
        //window.App.mask('Traitement en cours');
        this.currentRecord = row;
        this.recordKey = row.outputKeys();
        this.currentItem = item;
        this.currentAction = item.getVisibleActions()[id];
        if(this.currentAction.getType() === 'Notification'){

            //This is the notification we need to handle. We prepare
            let notification = this.currentAction.getNotification();
            let notificationContext = [];

            //We check if the notification is a Remote one or a Local one. In
            //case of remote notification, we'll have to request notification
            //content from the server before handling it
            new Promise((resolve, reject) => {
                if(notification === undefined) {
                    reject(I18NUtils.getMessage('INVALID_SERVER_RESPONSE', User.getLocale(), {
                        expected: 'notification',
                        got: 'undefined'
                    }));
                }

                if(notification.type === 'remote') {
                    //We've to fetch the result from the server. We fetch it on
                    //the given task with the given arguments. Arguments might
                    //need to be resolved from current row context. We thus 
                    //prepare a context for variables resolution.
                    let remoteTask = notification.remoteTask !== undefined ? notification.remoteTask : this.taskModel.get('taskId');
                    let remoteArgs = {};
                    let remoteArgValue = notification.remoteArguments;
                    if(remoteArgValue) {
                        this.fillContextFromRow(row, notificationContext);
                        for(let argEntry in remoteArgValue) {
                            let remoteArgKeyValue = CClientConfiguration.resolveTextProperties(remoteArgValue[argEntry], notificationContext);
                            remoteArgs[argEntry] = remoteArgKeyValue;
                        }
                    }
                    CClientConfiguration.getRemoteNotification(notification.notificationId, remoteTask, remoteArgs)
                        .then(resolve)
                        .catch(reject);
                } else {
                    if(!notification.notificationId && this.currentAction.notificationId) {
                        console.error('notification id is not into notification definition');
                        notification.id = this.currentAction.notificationId;
                    }
                    resolve(notification);
                }
            }).then((notificationContent) => {
                var manager = new NotificationManager({
                    currentTaskContext: this,
                    contextFiller : this.fillContextFromRow.bind(this, row),
                    domainContext: this.domainContext
                });
                me.listenTo(manager, 'notificationEnd', me.removeLock.bind(me, sourceId));
                manager.handle(notificationContent);
            }).catch((e) => {
                App.displayErrorMessage(e);
                me.removeLock(sourceId);
            });
        }else{
            let recordScreen = {};
            this.performGridAction(item.itemid, this.currentAction.getActionId(), this.currentAction.getPopupId(), this.currentAction.getItemId(), recordScreen, this.gridActionCallback.bind(this, sourceId), this.gridActionCallback.bind(this, sourceId));
        }
    }

    gridActionCallback(cid) {
        this.removeLock(cid);
    }

    removeLock(cid) {
        this.itemActionBuffer[cid] = undefined;
    }

    fillContextFromRow(row, context){
        var colValues = row.getColumnsValues();
        var headers = row.getHeaders();
        for(var key in headers.columns){
            var curCol = headers.columns[key];
            if(curCol.getDatafieldId() && curCol.getId() && colValues[curCol.getId()]){
                context[curCol.getDatafieldId()] = colValues[curCol.getId()].value;
            }
        }

        context['actionId'] = this.currentAction.getActionId();
        context['popupId'] = this.currentAction.getPopupId();
        context['itemId'] = this.currentAction.getItemId();
    }

    performGridAction(gridIndex, actionId, popupId, itemId, screenKeys, successCallback, failureCallback){
        gridIndex = gridIndex || this.currentItem.itemId;

        var gridContext = new Context();
        gridContext.setContextId('grid');
        gridContext.addPanelFields(this.currentRecord.outputAllValues(), '1');

        var recordContext = new Context();
        recordContext.setContextId('record');

        for(let id in Object.keys(screenKeys)) {
            let panelId = Object.keys(screenKeys)[id];
            let fields = screenKeys[panelId];
            recordContext.addPanelFields(fields, panelId);
        }

        var me = this;
        var successCall = function(result){
            me.navigator.updateRow(result);
            if(successCallback){
                successCallback(result);
            }
        };

        let gridRequest = new GridActionRequest(this.domainContext,
            this.taskConfig.getId(),
            this.taskConfig.getNavigatorGridView(),
            popupId || this.currentAction.popupId,
            itemId || this.currentAction.itemId,
            actionId || this.currentAction.actionId,
            gridIndex);

        gridRequest.setGridContext(gridContext);
        gridRequest.setRecordContext(recordContext);

        let loadingReqId;
        LoadingMask.requestLoading('Action en cours')
            .then((reqId) => {
                loadingReqId = reqId;
                return Server.performRequest(gridRequest);
            })
            .then(successCall)
            .catch(App.displayErrorMessage)
            .then(() => { LoadingMask.hide(loadingReqId); });
    }

    renderNavigatorTabBar() {
        if (DeviceUtils.isFullScreenPageNav()) {
            if(!this.isModal()) {
                if (!this.navigatorTabBar) {
                    this.navigatorTabBarEl = this.getNavigatorTabBarElement();

                    var tabs = this.getNavigatorTabBarTabs()
                        .then((tabs) => {
                            this.navigatorTabBar = new TabBarView({
                                el: this.navigatorTabBarEl,
                                prefix: 'nav',
                                tabs: tabs,
                                dockLocation: 'bottom',
                                domainContext: this.domainContext
                            });
                            this.navigatorTabBar.render();
                            this.listenTo(this.navigatorTabBar, 'tabPressed', this.onNavTabPressed.bind(this));
                            this.navigatorTabBarEl.show();
                            this.resizeTask('tablet');
                        });
                } else {
                    this.navigatorTabBarEl.show();
                }
            }
        } else {
            if (this.navigatorTabBar) {
                this.navigatorTabBarEl.hide();
            }

            if(this.inputTaskActionMenu === undefined) {

                this.inputTaskActionMenu = this.getDesktopMenuActions()
                    .then((items) => {
                        for(let i in items) {
                            let item = items[i];
                            this.desktopActionsMenu.addMenuItem(item);
                        }
                    });
            }
        }
    }

    initPresentation() {
        return super.initPresentation() | TaskPresentation.ShowNavigator | TaskPresentation.ShowRecordHeader;
    }

    getRemovedPresentationFromType(type) {
        let flags = super.getRemovedPresentationFromType(type);
        switch(type) {
            case TaskWindowType.Simplified:
                return flags | TaskPresentation.ShowNavigator | TaskPresentation.ShowRecordHeader;
            case TaskWindowType.FullSimplified:
            case TaskWindowType.MicroPopup:
                return flags | TaskPresentation.ShowNavigator | TaskPresentation.ShowRecordHeader;
        }
        return 0;
    }

    onNavTabPressed(tabId) {
        if (tabId === 'home') {
            window.App.backToDesktop();
        } else if (tabId === 'new') {
            this.createNewEntry(undefined, true);
        } else if (tabId === 'report') {
            this.displayGlobalTemplates();
        } else if (tabId === 'refresh') {
            this.navigator.refreshData();
        } else if (tabId === 'popupmenu'){
            this.onPopupMenuClick([PopupMenu.SCOPE_ALWAYS]);
        } else  if(tabId === 'back') {
            App.popTask();
        }
    }

    createNewEntry(initialValues, initialize) {
        App.setActionMode('newEntry');
        this.mode = 'newEntry';
        let me = this;
        //Warning, condition are reversed for tests
        return new Promise((accept, reject) => {
            if(me.taskConfig.canCallOnPrepareNewEntry()) {
                let prepareRequest = new PrepareNewEntryRequest(this.domainContext, me.taskConfig.getId());
                let taskContext = new Context({ contextId: 'record' });
                if(initialize === true) {
                    me.tabContainer.initializeContext();
                }
                me.tabContainer.onPrepareContext(taskContext, undefined);

                if(initialValues !== undefined) {
                    for(let k in initialValues) {
                        let obj = initialValues[k];
                        taskContext.updateField(obj['name'],undefined,obj['value']);
                    }
                }

                var filterValues = this.navigator.searchBar.getFilter().values;

                // ------ HARCODED TO REMOVE --------
                let sessionVar = {};
                for(let key in filterValues){
                    let currentFilterEntry = filterValues[key];
                    if(currentFilterEntry.datafieldId === 'CRM40_GTPEVT_MULTI_CHILDREN' && currentFilterEntry.value !== ''){
                        sessionVar = {
                            'CURRENT_CHILD' : currentFilterEntry.value
                        };
                    }
                }
                prepareRequest.setSessionVars(sessionVar);
                // ----------------------------------
                prepareRequest.setRecordContext(taskContext);
                let allFIds = taskContext.getFieldIds();
                for(let i in allFIds) {
                    taskContext.updateFieldStatus(allFIds[i], 'enabled', undefined);
                    taskContext.updateFieldStatus(allFIds[i], 'visible', undefined);
                }
                prepareRequest.setKeyContext(new Context({contextId: 'key'}));
                let loadingId;
                LoadingMask.requestLoading('Préparation de la nouvelle entrée')
                    .then((reqId) => {
                        loadingId = reqId;
                        return Server.performRequest(prepareRequest);
                    })
                    .then((result) => {
                        me.onPrepareRecordReceived(result);
                        accept();
                    })
                    .catch(reject)
                    .then(() => {
                        LoadingMask.hide(loadingId);
                    });
            } else {
                //No call to make we just enable the screen
                me.onPrepareRecordReceived({
                    task: me.taskConfig.getConfig()
                })
                accept();
            }
        })
    }

    onNavigatorItemSelected(id, row, item) {
        this.currentRecord = row;
        this.recordKey = row.outputKeys();
        if (this.isAllowedToBrowseEntity()) {
            this.loadRecord();
        } else {
            var headerItem = item.$el;
            var lockSpan = headerItem.find('.dossierLock');
            var icons = headerItem.find('.mainIcon, .thumbContainer');
            lockSpan.show();
            lockSpan.animate({opacity : 1}, {duration: 750, queue:  false});
            icons.animate({
                opacity : 0
            }, {
                duration: 750,
                queue: false,
                complete: function(){
                    lockSpan.animate({opacity : 0}, {duration: 750, queue:  false});
                    icons.animate({opacity : 1}, {
                        duration: 750,
                        queue: false,
                        complete : function(){
                            lockSpan.hide();
                        }
                    });
                }
            });
        }
    }

    isAllowedToBrowseEntity(){
        return this.canRead;
    }

    loadFiche() {
        this.ficheView.onDOMUpdated();
    }


    loadRecord() {
        this.makeMainRecordRetreivalCall();
    }

    makeMainRecordRetreivalCall(key) {
        let recordRequest = new GetRecordRequest(this.domainContext, this.taskConfig.getId());
        recordRequest.setGridId(this.taskConfig.getNavigatorGridView());

        let taskContext = new Context();
        taskContext.setContextId('key');
        if(key) {
            this.recordKey = key;
            taskContext.addFields(key);
        } else {
            taskContext.addFields(this.recordKey);
        }
        recordRequest.setKeyContext(taskContext);

        Server.performRequest(recordRequest)
            .then(this.onRecordReceived.bind(this))
            .catch(App.displayErrorMessage);
    }

    getMainRecordLoadingText() {
        return 'Chargement du record';
    }

    onRecordReceived(result) {
        this.buildOrSetRecordBanner(this.currentRecord);
        this.mode = 'modify';
        this.buildAndDisplayRecordView(result);
    }

    getTplsOfType(type) {
        var mergePopupMenu = this.tablePopup.appendOverride(this.taskPopup);
        let scope = ['AllVisibleRecords'];
        if(type === 'single') {
            scope.push('SingleRecord');
        }
        return mergePopupMenu.getActions(scope);
    }

    onPrepareRecordReceived(result) {
        App.setActionMode('prepareNewEntry');
        this.buildAndDisplayRecordView(result);
    }

    resizeTask(mode) {
        super.resizeTask(mode);
    }

    getNavigatorTabBarTabs() {
        let me = this;

        return new Promise((accept, resolve) => {
            var tabs = [
                {
                    id: 'home',
                    name: 'Home',
                    icon: 'home',
                    enabled: true
                }, {
                    id: 'refresh',
                    name: 'Rafraichir',
                    icon: 'refresh',
                    enabled: true
                }
            ];

            if (this.canCreate) {
                tabs.push({
                    id: 'new',
                    name: 'Nouveau',
                    icon: 'plus',
                    enabled: true
                });
            }

            if(App.hasCallStack()) {
                tabs.push({
                    id: 'back',
                    name: 'Retour',
                    icon: 'arrow-left',
                    enabled: true
                });
            }

            let loadingReqId;
            LoadingMask.requestLoading('Chargement des actions')
                .then((reqId) => {
                    loadingReqId = reqId;
                    //We only need to retrieve the popup menus once for all
                    if(me.tablePopup && me.taskPopup) {
                        return new Promise((found, failure) => {
                            found([me.tablePopup, me.taskPopup]);
                        })
                    } else {
                        return Promise.all([
                            CClientConfiguration.getPopupMenu('table', this.taskConfig.getTableID(), this.domainContext),
                            CClientConfiguration.getPopupMenu('task', this.taskConfig.getId(), this.domainContext)
                        ]);
                    }
                })
                .then(([tablePop, taskPop]) => {
                    me.tablePopup = tablePop;
                    me.taskPopup = taskPop;

                    var mergePopupMenu;
                    if(me.taskPopup) {
                        mergePopupMenu = tablePop.appendOverride(taskPop);
                    } else {
                        mergePopupMenu = tablePop.clone();
                    }

                    var actions = mergePopupMenu.getVisibleActions([PopupMenu.SCOPE_ALWAYS], undefined, undefined);

                    if(actions.length > 0) {
                        tabs.push({
                            id : 'popupmenu',
                            name : 'actions',
                            icon : 'cogs',
                            enabled : true,
                            popupmenu : mergePopupMenu
                        })
                    }
                    accept(tabs);
                })
                .catch(App.displayErrorMessage)
                .then(function(){ LoadingMask.hide(loadingReqId); });
        });
    }

    getDesktopMenuActions() {
        let me = this;

        return new Promise((accept, resolve) => {
            let refreshIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-refresh');
            let refreshItem = new MenuItemView({
                label: 'Rafraichir',
                iconPath: refreshIconPath,
                actionType: 'refresh',
                id : 'REFRESH'
            });

            var items = [refreshItem];

            if (this.canCreate) {
                let newDossierIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-new');
                let newItem = new MenuItemView({
                    label: 'Nouveau dossier',
                    iconPath: newDossierIconPath,
                    actionType: 'new',
                    id : 'NEW'
                });
                items.push(newItem);
            }

            let loadingReqId;
            LoadingMask.requestLoading('Chargement des actions')
                .then((reqId) => {
                    loadingReqId = reqId;
                    //We only need to retrieve the popup menus once for all
                    if(me.tablePopup && me.taskPopup) {
                        return new Promise((found, failure) => {
                            found([me.tablePopup, me.taskPopup]);
                        })
                    } else {
                        return Promise.all([
                            CClientConfiguration.getPopupMenu('table', this.taskConfig.getTableID(), this.domainContext),
                            CClientConfiguration.getPopupMenu('task', this.taskConfig.getId(), this.domainContext)
                        ]);
                    }
                })
                .then(([tablePop, taskPop]) => {
                    me.tablePopup = tablePop;
                    me.taskPopup = taskPop;

                    var mergePopupMenu;
                    if(me.taskPopup) {
                        mergePopupMenu = tablePop.appendOverride(taskPop);
                    } else {
                        mergePopupMenu = tablePop.clone();
                    }

                    var actions = mergePopupMenu.getVisibleActions([PopupMenu.SCOPE_ALWAYS], undefined, undefined);

                    for(let i in actions) {
                        let popupMenuItem = actions[i];
                        let popUpIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/'+popupMenuItem.getIcon());
                        let popupMenuActionItem = new MenuItemView({
                            label: popupMenuItem.getLabel(),
                            iconPath: popUpIconPath,
                            actionType: 'popupmenu',
                            actionArguments: popupMenuItem.getNotification()
                        });
                        items.push(popupMenuActionItem);
                    }
                    accept(items);
                })
                .catch(App.displayErrorMessage)
                .then(function(){ LoadingMask.hide(loadingReqId); });
        });
    }

    appendToPopupMenu(globalPopupMenu, popupMenuArray) {
        for (var tablePopupMenuElement in popupMenuArray) {
            if(globalPopupMenu === undefined) {
                globalPopupMenu = popupMenuArray[tablePopupMenuElement];
            } else {
                globalPopupMenu.merge(popupMenuArray[tablePopupMenuElement]);
            }
        }
        return globalPopupMenu
    }

    buildOrSetRecordBanner(record) {
        if (!this.recordBanner) {
            this.recordBannerEl = this.getRecordBannerElement();
            this.recordBanner = new HeaderListItem({
                el : this.recordBannerEl,
                row: record,
                itemId: 0, // irrelevant as this is just a display
                domainContext: this.domainContext,
                iconConfig: this.getConfig().getIconConfig(),
                allowExpand: true
            });
            this.listenTo(this.recordBanner, 'specificImageLoaded', this.handleSpecificImageChange.bind(this));

            if(this.handleIconLoaded){
                this.listenTo(this.recordBanner, 'iconLoaded', this.handleIconLoaded.bind(this));
            }
        } else {
            this.recordBanner.setRow(record);
        }
        this.recordBanner.render();
        this.recordBannerEl.show();
        this.recordBanner.onDOMUpdated();
        window.App.taskMenu.requestTaskSpecificIconUpdate(this.taskConfig.getId(), Server.getTokenedUrl(record.get('icon')));

    }

    handleSpecificImageChange(photoUrl){
        window.App.taskMenu.requestTaskSpecificIconUpdate(this.taskConfig.getId(), photoUrl);
    }

    buildAndDisplayRecordView(result) {
        //To override within the subclass
    }

    getMainNavigatorElement() {
        return this.$el.find('.navigatorContainer');
    }

    getNavigatorTabBarElement() {
        return this.$el.find('.navTabBar');
    }

    getRecordBannerElement() {
        return this.$el.find('.recordBanner');
    }

    displayGlobalTemplates() {
        this.currentTplPop = new TemplatesListPopupView({
            templates: this.getTplsOfType('global'),
            taskId: this.taskModel.get('lists').taskId,
            domainContext: this.domainContext
        });
        this.currentTplPop.display();
        this.listenTo(this.currentTplPop, 'templateSelected', this.onGlobalTplSelected.bind(this));
    }

    onGlobalTplSelected(id) {
        this.currentTplPop.hide();
        //Retreiving the tpl from the available tpl. Only one possible and it always exists.
        var tpl = _.find(this.taskModel.get('lists').templates, function (tplLookup) {
            return tplLookup.id === id;
        });
        TemplateManager.buildAndDownloadTemplate(this.taskModel.get('taskId'), this.taskModel.get('lists').taskId, undefined, tpl);
    }

    displayLocalTemplates() {
        this.currentTplPop = new TemplatesListPopupView({
            templates: this.getTplsOfType('single'),
            taskId: this.taskModel.get('lists').taskId,
            domainContext: this.domainContext
        });
        this.currentTplPop.display();
        this.listenTo(this.currentTplPop, 'templateSelected', this.onLocalTplSelected.bind(this));
    }

    onLocalTplSelected(id) {
        this.currentTplPop.hide();
        //Retreiving the tpl from the available tpl. Only one possible and it always exists.
        var tpl = _.find(this.taskModel.get('lists').templates, function (tplLookup) {
            return tplLookup.id === id;
        });
        TemplateManager.buildAndDownloadTemplate(this.taskModel.get('taskId'), this.taskModel.get('lists').taskId, this.recordKey, tpl);
    }

    switchToAlbum() {
        //We simulate the view switch by hiding the dossier
        this.taskHeader.setupContextAction(this.getAlbumContextActions());
        window.App.taskMenu.requestTaskSpecificIconUpdate(this.taskConfig.getId(), Server.getTokenedUrl(this.currentRecord.get('icon')));
        this.navigator.$el.hide();
        this.getAlbumElement().show();
    }

    getAlbumElement() {
        if (!this.albumElement) {
            this.albumElement = this.$el.find('.albumContainer');
        }
        return this.albumElement;
    }

    /*loadAlbum() {
        this.mainRecordAlbum = new AlbumView({
            extraClass: '',
            model: this.taskModel,
            taskId: this.taskModel.get('taskId'),
            el: this.getAlbumElement()
        });
        this.mainRecordAlbum.render();

        this.mainRecordAlbum.onDOMUpdated();

        this.taskHeader.setupContextAction(this.getAlbumContextActions());
    }*/

    getAlbumContextActions() {
        return [{
            name: 'documents',
            icon: 'file_circle'
        }, {
            name: 'record',
            icon: 'personal'
        }];
    }

    getRecordContextActions() {
        return [{
            name: 'album',
            icon: 'camera_circle'
        }, {
            name: 'documents',
            icon: 'file_circle'
        }];
    }

    getDocumentsContextActions() {
        return [
            {
                name: 'album',
                icon: 'camera_circle'
            }, {
                name: 'record',
                icon: 'personal'
            }
        ];
    }

    handleTaskHeaderAction(action, actionArguments) {
        /*if (action === 'album') {
            this.switchToAlbum();
            this.loadAlbum();
        } else */
        if (action === 'record') {
            this.loadRecord();
        } else if (action === 'popupmenu') {
            this.onCustomActionSelected({
                type: 'Notification',
                notification: actionArguments.notification ? actionArguments.notification : actionArguments
            })
        } else if(action === 'new') {
            this.createNewEntry(undefined, true);
        } else {
            console.warn('No handler for action ' + action + ' in dossier');
        }
    }

    onPopupMenuClick(scope) {
        var popupmenu = this.getTabById('popupmenu');
        if (popupmenu !== undefined) {
            var popupMenuDisplay = new PopupMenuNavigator({
                popupmenu : popupmenu.popupmenu,
                domainContext: this.domainContext,
                scope: scope
            });
            popupMenuDisplay.display();
            popupMenuDisplay.on('actionSelected', this.onCustomActionSelected.bind(this))
        }
    }

    onCustomActionSelected(item, modalItem) {
        if(item.type === 'Notification') {
            let notificationManager = new NotificationManager({
                currentTaskContext: this,
                domainContext: this.domainContext,
                contextFiller: this.fillContextFromTask.bind(this, item),
            })
            if (item.notification.type === 'local') {
                notificationManager.handle(item.notification);
            } else {
                let notif = item.notification;
                let remoteArgs = {};
                for(let key in notif.remoteArguments) {
                    remoteArgs[key] = StringUtils.resolveTextProperties(notif.remoteArguments[key], notificationManager.context);
                }
                
                CClientConfiguration.getRemoteNotification(notif.notificationId, notif.remoteTask, remoteArgs)
                    .then((notif) => {
                        notificationManager.handle(notif);
                    })
                    .catch(App.displayErrorMessage);
            }
            if(modalItem) {
                modalItem.modal('hide');
            }
        }
    }

    fillContextFromTask(item, context) {
        if(this.currentRecord) {
            let row = this.currentRecord;
            var colValues = row.getColumnsValues();
            var headers = row.getHeaders();
            for(var key in headers.columns){
                var curCol = headers.columns[key];
                if(curCol.getDatafieldId() && curCol.getId()){
                    context[curCol.getDatafieldId()] = colValues[curCol.getId()].value;
                }
            }

            context['actionId'] = item.actionId;
            context['popupId'] = item.popupId;
            context['itemId'] = item.itemId;

            if(this.selectedPage) {
                let pageConfig = this.selectedPage.getConfig();
                context['CURRENT_PAGE_ID'] = pageConfig.pageOcc;
                context['CURRENT_DATAPAGE_ID'] = pageConfig.pageId;
                context['PAGE'] = pageConfig.pageId;
            }
        }
    }

    getTabById(tabId) {
        for (var tab in this.navigatorTabBar.tabs) {
            if (this.navigatorTabBar.tabs[tab].id === tabId) {
                return this.navigatorTabBar.tabs[tab];
            }
        }
        return undefined;
    }

    getKeys() {
        return this.recordKey;
    }

    initializeConfig() {
        let me = this;
        return new Promise((accept, reject) => {
            let iconConfigPromise = [];
            let taskConfig = me.options.taskConfig;
            let navGridView = taskConfig.getNavigatorGridView();
            let navTableId = taskConfig.getNavigatorTableID();
            iconConfigPromise.push(CClientConfiguration.getGridIconConfig(navGridView,taskConfig.getId(), me.domainContext));
            iconConfigPromise.push(CClientConfiguration.getTableIconConfig(navTableId,taskConfig.getId(), me.domainContext));
            Promise.all(iconConfigPromise)
                .then(([gridIconConfig, tableIconConfig]) => {
                    if(gridIconConfig || tableIconConfig) {
                        let iconConfig = Object.assign({}, gridIconConfig, tableIconConfig);
                        taskConfig.setIconConfig(iconConfig);
                        me.navigator.iconConfig = iconConfig;
                    }
                    accept();
                })
                .catch(reject);
        });
    }
}

export default InputTaskView;
