/* global me */
import ModalView from 'views/modal/ModalView';
import List from 'views/listing/List';
import SearchBar from 'views/filter/SearchBar';
import HeaderRenderer from 'views/listing/renderer/header/HeaderRenderer';

import Logger from 'utils/Logger';

class HeaderListPopup extends ModalView {
    initialize(options){
        super.initialize(options);
        this.checkMandatoryParameter(options, 'taskId', 'Task to call for data');
        this.checkMandatoryParameter(options, 'gridId', 'Grid to retreive from server');
        this.checkMandatoryParameter(options, 'filterId', 'Filter to apply');
        this.parameters.foreignKey = options.foreignKey;
        this.parameters.title = options.title;
        this.parameters.showSearchbar = options.showSearchbar != undefined ? options.showSearchbar : true;
        this.parameters.multiSelect = options.multiSelect;
        this.classIdentifier = 'headerListPopup';
        if(this.parameters.multiSelect) {
            this.classIdentifier += ' multi-selection';
        }
        this.domainContext = options.domainContext;
        this.iconConfig = options.iconConfig;
        this.sessionVars = options.sessionVars;
        this.selection = options.initialSelection || [];

        this.headerList = new List({
            taskId: this.parameters.taskId,
            gridId: this.parameters.gridId,
            filterId: this.parameters.filterId,
            renderer: new HeaderRenderer({
                domainContext: options.domainContext,
                iconConfig: this.iconConfig,
                itemClass: options.itemClass
            }),
            domainContext: options.domainContext,
            sessionVars: this.sessionVars,
            multiSelect: this.parameters.multiSelect ? {
                selectedItemsClass: 'selectedItem',
                selectedKeys: this.selection.map(function (item) {
                    return item;
                }),
                keyColumn: this.parameters.foreignKey
            } : undefined
        });

        if(this.selection.length > 0) {
            //We've initial data
            this.headerList.setSelectedKeys(this.selection);
        }
        this.listenTo(this.headerList,'list.onPrepareContext', this.onContextRequested.bind(this));
    }

    onContextRequested(context) {
        this.trigger('headerListPopup.onPrepareContext', context);
    }

    getHeaderList(){
        return this.headerList;
    }

    renderInnerPanel(element){
        var me = this;
        if(this.parameters.title !== undefined){
            var header = $('<div class="modal-header"></div>').appendTo(element);
            $('<div class="titleText">'+this.parameters.title+'</div>').appendTo(header);
        }
        var body = $('<div class="modal-body"></div>').appendTo(element);
        // ------ HARDCODED FOR PMI ----
        if(this.parameters.taskId === 'CRM40' && this.parameters.gridId === 'CRM06_ENFANTS'){
            body.css('max-height', 537);
        }
        //------------------------------
        var footer = $('<div class="modal-footer"></div>').appendTo(element);

        if(this.parameters.showSearchbar) {
            this.searchBar = new SearchBar({
                el: footer,
                taskId: this.options.taskId,
                filterId: this.options.filterId,
                filters: this.options.filters,
                enabled: true,
                domainContext: this.domainContext,
                hideCriterias: true
            });
            this.searchBar.render();

            this.listenTo(this.searchBar, 'searchChanged', function(newValue, customized){
                var filter = me.searchBar.getFilter();
                me.headerList.getListing().resetData(true);
                me.headerList.getListing().setFilterData(filter.values, filter.text);
                if(customized){
                    me.headerList.getListing().setSessionVar('CUSTOMIZED_FILTER', true);
                }else{
                    me.headerList.getListing().setSessionVar('CUSTOMIZED_FILTER', undefined);
                }
                this.headerList.getListing().fetchNextPage();
            });
        }

        //Creating the cancel Button. There is a ok button if we're in multi selection, or the list will immediately trigger the dismiss
        if(this.parameters.multiSelect) {
            var selectBtn = $('<button type="button" role="select" class="btn btn-modal-select">Sélectionner</button>').appendTo(footer);
            selectBtn.one('click', this.onSelectClick.bind(this));
        }

        var cancelBtn = $('<button type="button" role="cancel" class="btn btn-modal-cancel">Annuler</button>').appendTo(footer);
        cancelBtn.one('click', this.onCancelClick.bind(this));

        //Creating the listing that will be displayed
        this[this.parameters.multiSelect ? 'listenTo' : 'listenToOnce'](this.headerList, 'itemClicked', this.onItemClicked.bind(this));

        this.headerList.setElement(body);
        this.headerList.render();

        this.headerList.getListing().fetchNextPage();
    }

    onCancelClick(){
        //This will allow the caller to know that the user pressed cancel on purpose
        this.$el.modal('hide');
        this.trigger('userCancelled');
    }

    onSelectClick(){
        this.$el.modal('hide');
        this.trigger('selection', this.selection.join(','), null);
    }

    onItemClicked(row, id, item){
        var allValues = row.getColumnsValues();
        var selectionValue = '';
        if(this.parameters.foreignKey !== undefined){
            //Specific selection of a column
            if(allValues[this.parameters.foreignKey]){
                selectionValue = allValues[this.parameters.foreignKey].value;
            }else{
                Logger.error('HeaderListPopup', 'itemClicked', 'The selection column has been set in the parametrage but didn\'t appear in the grid columns. Contact your administrator');
                this.$el.modal('hide');
                this.trigger('error');
            }
        }else{
            for(var key in allValues){
                if(allValues[key].key){
                    selectionValue = allValues[key].value;
                }
            }
        }

        if(this.parameters.multiSelect) {
            if(!this.selection.includes(selectionValue)){
                this.selection.push(selectionValue);
            }else{
                this.selection.splice(this.selection.indexOf(selectionValue), 1);
            }
        } else {
            this.$el.modal('hide');
            this.trigger('selection', selectionValue, row);
        }
    }
}

export default HeaderListPopup;